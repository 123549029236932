import React from 'react';

const StudentOrgDummy = () => {
  return (
  <div>
      <h1>Student Org page under construction</h1>
  </div>
  );
};

export default StudentOrgDummy;
