import React from 'react';
import './Home.css'
import appStore from '../assets/downloadAppStore.svg';
import playStore from '../assets/downloadPlayStore.png'
import screenshot from '../assets/screenshot.png';

const Home = () => {
  return (
      <div className="HomeContainer">
          <div className="infoContainer">
            <h1 className="homeHeader">College <br/> Campus App</h1>
            <p className="homeSubheader">Tyne is an "All-In-One" college campus community app exclusively for
            college students and graduated alumni. We strive to help college students in every part of their student lives
            and provide myriad of helpful features. Download the app now!
            </p>

            <div className="downloadIconContainer">
                <div className="appStoreDiv">
                  <a href='https://apps.apple.com/app/tyne-campus-community/id1627181167'>
                    <img src={appStore} alt='' className="appStoreIcon"></img>
                  </a>
                </div>
                <div className="playStoreDiv">
                  <a href='https://play.google.com/store/apps/details?id=com.tyne.tyne'>
                    <img src={playStore} alt='' className="playStoreIcon"></img>
                  </a>
                </div>
            </div>
          </div>

          <div className="visualContainer">
            <img src={screenshot} className="screenshot" alt=''></img>
          </div>
      </div>
  )
};

export default Home;
