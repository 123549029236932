import React from 'react'
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from './pages/Home'
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import StudentOrgDummy from './pages/StudentOrgDummy';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/contactUs">
              <ContactUs />
            </Route>

            <Route exact path="/aboutUs">
              <AboutUs />
            </Route>
            
            <Route exact path="/studentOrgs">
              <StudentOrgDummy />
            </Route>

            <Route exact path="/privacy">
              <Privacy />
            </Route>

            <Route exact path="/legal">
              <Terms />
            </Route>
            
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
