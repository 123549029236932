import React from 'react';
import './AboutUs.css'

const AboutUs = () => {
  return (
  <div className='AboutUsContainer'>
      <div className='aboutUs-Info'>
        <h1  id="bootstrap-overrides">ABOUT US</h1>
        <p id="bootstrap-overrides"
        style={{fontSize: '20px'}}
        >We are a group of college graduates who got together in a mission to make student life at college more interactive, efficient, and fun. 
        We brainstormed many challenges and conflicts that can arise during your time at college, in the perspective of being once students ourselves, and carefully crafted the solutions in this app.
        As you use this app, you will find out that many features are presented anonymously. 
        We believe that by being able to communicate anonymously, students would be able to openly interact with others in their true selves.
        </p>
      </div>
  </div>
  )
};

export default AboutUs;
