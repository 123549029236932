import React from 'react';
import './ContactUs.css'
import emailjs from 'emailjs-com'

const ContactUs = () => {

  
  function sendEmail(e) {
    console.log(e)
    e.preventDefault();
    emailjs.sendForm('service_yk42gpx', 'template_cmy4xzd', e.target, 'user_K78N0kkSKqRBVAFwj7oIq'
    ).then(res=> {
      console.log(res)
      document.getElementById("contact-form").reset();
      alert("Your message has been successfully sent!");
    }).catch(err=> {
      console.log(err)
      alert("An error occured while trying to send your message");
    });
  }
  return (
  <div className='container border'
    style = {{ marginTop: "50px",
    width: '75%',
  }} >
      <h1 className='contactTitle'>Contact</h1>
      <form className="form-row" id="contact-form" onSubmit={sendEmail}>
        <label className="contact-form-label">Full Name</label>
        <input type='text' name='name' className='form-control'/>

        <label className="contact-form-label">Email</label>
        <input type='email' name="user_email" className='form-control'/>

        <label className="contact-form-label">Message</label>
        <textarea name="message" rows='4' className='form-control'/>
        <input type='submit' value='Send'className='form-control' btn btn-primary
        style={{marginTop: "30px"}}
        />
      </form>
  </div>
  )
};

export default ContactUs;
