export const MenuItems = [
    {
        title: 'STUDENT ORGS',
        url: 'https://orgs.tyne.app',
        cName: 'nav-links'
    },

    {
        title: 'CONTACT US',
        url: '/contactUs',
        cName: 'nav-links'
    },

    {
        title: 'ABOUT US',
        url: 'aboutUs',
        cName: 'nav-links'
    },

    {
        title: 'Terms',
        url: 'legal',
        cName: 'nav-links'
    },

    {
        title: 'Privacy',
        url: 'privacy',
        cName: 'nav-links'
    },

]
